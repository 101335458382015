import React from "react";
import { pure } from "recompose";
import { H2, Button } from "../../../../../../Atoms";
import "./GraphSection.css";
import moment from "moment"

import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginRight: theme.spacing(1),
    lineHeight: '27px',
  },
}));


const GraphSection = props => {

const classes = useStyles();
const [month, setMonth] = React.useState( moment().date()>=15 ? moment().subtract(1, 'months').month() + 1 : moment().subtract(2, 'months').month() + 1);
const [year, setYear] = React.useState( month===11 && moment().date()<=14 ? moment().subtract(1, 'years').year() : month===12 ? moment().subtract(1, 'years').year() : moment().year());

const handleChange = (event) => {
    setMonth(event.target.value);
};

const handleYearChange = (event) => {
    setYear(event.target.value);
};

return(
    <div className="pagePadding-Investor paddingTopXL paddingBottomXXL GraphSection">
        <Grid className="PieChartSection" container alignItems="center" direction="row">
            <Grid item xs={8}>
                <H2 className="fontSize28">Read this Month's Fund Report:</H2>
                <div className="paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                    We'll let the numbers speak for themselves.
                </div>
            </Grid>
            <Grid item xs={4}>
                <div className="paddingTopL">

                    <FormControl className={classes.formControl}>
                    <div>
                        <Select
                          value = {year}
                          onChange={handleYearChange}
                          //displayEmpty
                          className={classes.selectEmpty}
                          //inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={2020}>2020</MenuItem>
                          <MenuItem value={2021}>2021</MenuItem>
                          <MenuItem value={2022}>2022</MenuItem>
                          <MenuItem value={2023}>2023</MenuItem>
                          <MenuItem value={2024}>2024</MenuItem>
                        </Select>
                        <Select
                          value = {month}
                          onChange={handleChange}
                          //displayEmpty
                          className={classes.selectEmpty}
                          //inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={1}>January</MenuItem>
                          <MenuItem value={2}>February</MenuItem>
                          <MenuItem value={3}>March</MenuItem>
                          <MenuItem value={4}>April</MenuItem>
                          <MenuItem value={5}>May</MenuItem>
                          <MenuItem value={6}>June</MenuItem>
                          <MenuItem value={7}>July</MenuItem>
                          <MenuItem value={8}>August</MenuItem>
                          <MenuItem value={9}>September</MenuItem>
                          <MenuItem value={10}>October</MenuItem>
                          <MenuItem value={11}>November</MenuItem>
                          <MenuItem value={12}>December</MenuItem>
                        </Select>
                    </div>
                    </FormControl>

                    <Button
                        onClick={() => {
                            if((month == 1) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ETA0WtdeLVtFsVSAbgblw0MBTEPLMaiOj3NcNHoPlv-h7A?e=3nuPs6"
                                );
                            };
                            if((month == 2) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYbh0S11ZQdAhAgsSp8KpHkBpeByft7eoudiD6onFgPW5g?e=IJ4ckM"
                                );
                            };
                            if((month == 3) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EY1b1bDFGWpAkHwyJ6t5efMBWdKv0bBiqwhMTP0oLbSsug?e=Ycsi2c"
                                );
                            };
                            if((month == 4) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/ESwp8bqqg-5GiEwkAf6is5UBIZiw1SEBg7fwcwCKn_d0TA?e=mL5Rfn"
                                    
                                );
                            };
                            if((month == 5) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EXlMFYg2F69Agg5Om1IvkrYBOU2loPGbJwbg8HGI_t92QA?e=aqLfQS"
                                );
                            };
                            if((month == 6) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYNgpDh2YBNPmbKhfqV5e0oBwOmqa4hcD11SOP89pQfJBg?e=4FQeHh"
                                );
                            };
                            if((month == 7) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/Efr8DaDJx0NOq4sP2u3b_-gB-Ik3Le6u2RE-IZdT6ppLow?e=2udq6P"
                                );
                            };
                            if((month == 8) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EahzcJVXiiRFu9j7itT-iIwBUoPzf1ggwMSfjNSRyBoEtQ?e=Nf70Da"
                                );
                            };
                            if((month == 9) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EYsOg9SIEQ5Ileqj9WZ4OdcBNkkTdx1IXP5cTF5hDoOD4w?e=h1y3ac"
                                );
                            };
                            if((month == 10) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EVENNFxlJVdGnrhxqrjFWZABQ8UnAsaGMD9yAjWIWv2kmQ?e=RNZMDr"
                                );
                            };
                            if((month == 11) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EfxfH-CAlPtEpCRxsCUOjNoBL9YxcTWu-5UG15qgym6FKw?e=7XBcRz"
                                );
                            };
                            if((month == 12) && (year == 2024)){
                                window.open(
                                    "https://ginkgomic.sharepoint.com/:b:/g/EXE1XI-VIUFFmb4LFHOgOhABoqt6lOtIcQi0U_A_1ryKkQ?e=GuuHu1"
                                );
                            };


                            if((month == 1) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3K1yPmf"
                                );
                            };
                            if((month == 2) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3JYhgn3"
                                );
                            };
                            if((month == 3) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3HXauLp"
                                );
                            };
                            if((month == 4) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3HYtc5s"
                                );
                            };
                            if((month == 5) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3K68XFE"
                                );
                            };
                            if((month == 6) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3lxrfpj"
                                );
                            };
                            if((month == 7) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/40N6Ah0"
                                );
                            };
                            if((month == 8) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3IiXql5"
                                );
                            };
                            if((month == 9) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3JXsHeF"
                                );
                            };
                            if((month == 10) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/40OvkFD"
                                );
                            };
                            if((month == 11) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3IhZ32l"
                                );
                            };
                            if((month == 12) && (year == 2023)){
                                window.open(
                                    "https://bit.ly/3YHyw3Z"
                                );
                            };

                            if((month == 1) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3tdCHaS"
                                );
                            };
                            if((month == 2) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3u6XwUw"
                                );
                            };
                            if((month == 3) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3tYX2jk"
                                );
                            };
                            if((month == 4) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3JlQVfl"
                                );
                            };
                            if((month == 5) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3thb9kN"
                                );
                            };
                            if((month == 6) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3whVL9N"
                                );
                            };
                            if((month == 7) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3iiZFqA"
                                );
                            };
                            if((month == 8) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3tfYjTU"
                                );
                            };
                            if((month == 9) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3u0YD8d"
                                );
                            };
                            if((month == 10) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3wacdc3"
                                );
                            };
                            if((month == 11) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3BxFeAv"
                                );
                            };
                            if((month == 12) && (year == 2022)){
                                window.open(
                                    "https://bit.ly/3JjqFSZ"
                                );
                            };
                            if((month == 1) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3MWvYdd"
                                );
                            };
                            if((month == 2) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3CPuwVp"
                                );
                            };
                            if((month == 3) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3N0XMgK"
                                );
                            };
                            if((month == 4) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3u43plt"
                                );
                            };
                            if((month == 5) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3wdORSQ"
                                );
                            };
                            if((month == 6) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/367XJyN"
                                );
                            };
                            if((month == 7) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3CRXvb1"
                                );
                            };
                            if((month == 8) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3KRmle8"
                                );
                            };
                            if((month == 9) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3u5bqXb"
                                );
                            };
                            if((month == 10) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3igm20a"
                                );
                            };
                            if((month == 11) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3uaOyWC"
                                );
                            };
                            if((month == 12) && (year == 2021)){
                                window.open(
                                    "https://bit.ly/3MWxzjd"
                                );
                            }   
                            if((month == 1) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/34SWGSY"
                                );
                            };
                            if((month == 2) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3u7ZFQ5"
                                );
                            };
                            if((month == 3) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/37CeWku"
                                );
                            };
                            if((month == 4) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3699e98"
                                );
                            };
                            if((month == 5) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3qbFuzz"
                                );
                            };
                            if((month == 6) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3KRjeTu"
                                );
                            };
                            if((month == 7) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3IhbLeK"
                                );
                            };
                            if((month == 8) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3IgHson"
                                );
                            };
                            if((month == 9) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/34MLDum"
                                );
                            };
                            if((month == 10) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3u82Kj7"
                                );
                            };
                            if((month == 11) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3ibw6aH"
                                );
                            };
                            if((month == 12) && (year == 2020)){
                                window.open(
                                    "https://bit.ly/3IfQn9p"
                                );
                            }   

                        }}
                        className="leftButton"
                    >
                        SEE REPORT
                    </Button>
                </div>
            </Grid>
        </Grid>
    </div>
);
}

export default pure(GraphSection);
